@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
}

html {
  font-size: 62.5%;
  /* 62.5% of 16px = 10px */
}

body {
  /* background: rgb(75, 19, 7);
  background: radial-gradient(circle, rgba(75, 19, 7, 1) 0%, rgba(19, 1, 35, 100) 100%); */
  background-image: url('./assets/images/propokerBg.png');
  background-size: 100% 100%;

  /* background: rgb(75, 19, 7);
  background: radial-gradient(circle, rgba(75, 19, 7, 1) 0%, rgba(47, 7, 75, 1) 35%, rgba(19, 1, 35, 1) 100%); */

  /* background: rgb(75,19,7);
background: radial-gradient(circle, rgba(75,19,7,1) 0%, rgba(47,7,75,1) 26%, rgba(19,1,35,1) 44%); */
}

.home-input {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 1rem 1rem;
  outline: 0;
  width: 20rem
}

.home-input::placeholder {
  color: grey
}

.cardBg {
  /* width: 60rem;
  height: 45rem; */
  width: 50rem;
  aspect-ratio: 1.2/1;
  position: absolute;
  right: -7%;
  bottom: -5%
    /* transform:translateX(0%) translateY(10%) */
}

.secondaryNav {
  padding: 1rem 2rem;
  border-radius: 15rem;
  flex: 1;
  /* margin: 0 1rem; */
  text-align: 'center';
  text-decoration: none;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.3rem;
  min-width: 15rem;
  transition: all 300ms;
}

::-webkit-scrollbar {
  width: .5rem;
  border-radius: 10rem;
}

::-webkit-scrollbar-track {
  border-radius: 10rem;
  background: radial-gradient(circle, rgba(75, 19, 7, 1) 0%, rgba(47, 7, 75, 1) 35%, rgba(19, 1, 35, 1) 100%);
}


::-webkit-scrollbar-thumb {
  background: radial-gradient(circle, rgba(75, 19, 7, 1) 0%, rgba(19, 1, 35, 100) 100%);
}

@media only screen and (max-width:1200px) {
  .cardBg {
    position: static;
    min-width: 25rem;
    width: 100%;
    aspect-ratio: 1.2/1;
    margin-bottom: 2rem;
    max-width: 35rem;
  }
}


.navbarItems {
  font-size: '1.5rem';
  text-decoration: 'none';
  /* color: #cbc3c3e3; */
}

.center-logo {
  border-radius: 173.885px;
  box-shadow: 0px 0px 472.93px #C3A7CA, 0px 0px 270.246px #C3A7CA, 0px 0px 157.643px #C3A7CA, 0px 0px 78.8216px #C3A7CA, 0px 0px 22.5205px #C3A7CA, 0px 0px 11.2602px #C3A7CA;
}

